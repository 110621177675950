import {getColor} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

type StyledPathStyledProps = {
  $isDisabled?: boolean;
};

type Props = {
  isDisabled?: boolean;
};

const StyledPath = styled.path<StyledPathStyledProps>`
  stroke: ${props =>
    props.$isDisabled
      ? getColor("gray", "100", "solid")
      : getColor("blue", "700")};
`;

const CalendarIcon = ({isDisabled}: Props) => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath
      $isDisabled={isDisabled}
      d="M11.8889 3H4.11111C3.49746 3 3 3.49746 3 4.11111V11.8889C3 12.5025 3.49746 13 4.11111 13H11.8889C12.5025 13 13 12.5025 13 11.8889V4.11111C13 3.49746 12.5025 3 11.8889 3Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <StyledPath
      $isDisabled={isDisabled}
      d="M10 2V4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <StyledPath
      $isDisabled={isDisabled}
      d="M6 2V4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <StyledPath
      $isDisabled={isDisabled}
      d="M3 7H13"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
);

export default CalendarIcon;
